//
// Keep emergency contact in sync with spouse fields
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "spouseName",
    "spouseEmail",
    "emergencyContactName",
    "emergencyContactEmail",
    "emergencyContactRelationship",
    "instructions"
   ]

  get enabled() {
    return this.emergencyContactRelationshipTarget.value == "spouse";
  }

  connect() {
    this.sync();
  }

  sync(){
    this.toggleInstructions();
    if(this.enabled) {
      this.emergencyContactNameTarget.value = this.spouseNameTarget.value;
      this.emergencyContactEmailTarget.value = this.spouseEmailTarget.value;
    }
    this.emergencyContactNameTarget.readOnly = this.enabled;
    this.emergencyContactEmailTarget.readOnly = this.enabled;
  }

  toggleInstructions() {
    if(this.enabled) {
      this.instructionsTarget.classList.remove('hidden');
    } else {
      this.instructionsTarget.classList.add('hidden');
    }
  }
}
