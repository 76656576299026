//
// Show and hide a content area when an action happens (like a link click)
//
// To use this controller:
//
// <div data-controller="toggle-visibilty">
//    <button data-action="click->toggle-visibility#toggle">
//      Toggle me!
//    </button>
//
//    <div data-visibility-target="content">
//      <p>This is the area that will be toggled</p>
//      <p>Clicking anywhere outside the entire area will automatically hide the area again</p>
//    </div>
// </div>
//
import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    useClickOutside(this)
    this.contentTarget.classList.contains('hidden') ? this.hide() : this.show();
  }

  toggle(event) {
    this.contentTarget.classList.contains('hidden') ? this.show() : this.hide();
  }

  show(event) {
    this._stopDefault(event);
    this.contentTarget.classList.remove('hidden');
    document.body.classList.add('scroll-lock');

  }

  hide(event) {
    this._stopDefault(event);
    this.contentTarget.classList.add('hidden');
    document.body.classList.remove('scroll-lock');

  }

  clickOutside(event) {
    this.hide();
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
