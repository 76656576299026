//
// Allow clicking on a dom element to trigger a click elsewhere
//
// The HTML spec does not allow for interactive content to be nested within a
// link tag.  This means having a link inside a link or a button inside a link
// is not permitted.  This controller allows you to write normal markup, but
// then wrap it in a non-interactive element and trigger a click event of a
// specific interactive element within the controller scope.
//
// To use this controller:
//
// <div data-controller="deep-link" data-action: "click->deep-link#click">
//    <h1>Clicking anywhere inside this div will trigger the click event of the important link</h1>
//    <a href="example.com/minor">A minor, unimportant page</>
//    <a href="example.com/important" "data-deep-link-target"="link">The super important link</a>
// </div>
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "link"
  ]

  click(event) {
    if(!this._interactiveElement(event.target)) {
      this.clicking = true;
      this.linkTarget.click();
    }
  }

  _interactiveElement(el) {
    const bypassElements = ["A", "BUTTON", "INPUT", "SELECT"];
    return bypassElements.includes(el.nodeName)
  }
}
