//
// Handle a stripe credit card form by obtaining a stripe token and submitting
// it via a Rails form.
//
// To use this controller:
//
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "cardNumber",
    "cardCvv",
    "cardExpiryMonth",
    "cardExpiryYear",
    "token",
    "errorContainer",
    "errorMessage"
  ]

  static values = {
    key: String
  }

  get form() {
    return this.element;
  }

  connect() {
    this.clearError();
    Stripe.setPublishableKey(this.keyValue);
  }

  get params(){
    return {
      number: this.cardNumberTarget.value,
      cvc: this.cardCvvTarget.value,
      exp_month: this.cardExpiryMonthTarget.value || 0,
      exp_year: this.cardExpiryYearTarget.value || 0
    }
  }

  submit(event){
    this.clearError();
    this._stopDefault(event);

    this.disableForm();

    Stripe.createToken(this.params, function(status, response){
      if(response.error){
        this.showError(response.error);
        this.enableForm();
      } else {
        this.handleSuccess(response);
      }
    }.bind(this));
  }

  showError(error) {
    this.errorContainerTarget.classList.remove("hidden");
    this.errorMessageTarget.innerText = error.message;
  }

  clearError() {
    this.errorContainerTarget.classList.add("hidden");
    this.errorMessageTarget.innerText = "";
  }

  handleSuccess(response) {
    this.tokenTarget.value = response.id;
    this.form.submit();
  }

  disableForm() {
    this.form.querySelectorAll("input:not([type=hidden])").forEach(function (input) {
      input.disabled = true;
    });
  }

  enableForm() {
    this.form.querySelectorAll("input").forEach(function (input) {
      input.disabled = false;
    });
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
