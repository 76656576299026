//
// Update the points on the new member calculator
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "totalPoints",
    "categoryOne",
    "categoryTwo",
    "categoryThree",
    "message"
  ]

  connect() {
    this.update();
  }

  get exceededSectionTotal() {
    return this.sumQuestionPoints(this.categoryOneTarget) >= 30 ||
      this.sumQuestionPoints(this.categoryTwoTarget) >= 30
  }

  get exceededTotalPoints() {
    return this.total > 75;
  }

  get total() {
    let calcTotal = 0;
    [
      this.categoryOneTarget,
      this.categoryTwoTarget,
      this.categoryThreeTarget
    ].forEach(target => {
      calcTotal += this.sumQuestionPoints(target);
    })
    return calcTotal;
  }

  get message() {
    let msg = [];

    if(this.exceededTotalPoints && this.exceededSectionTotal) {
      msg.push("Congratulations!");
    }

    if(this.exceededTotalPoints) {
      msg.push("You have exceeded the 75 point standard.");
    } else {
      msg.push("You have not exceeded the 75 point standard.");
    }

    if(this.exceededSectionTotal){
      msg.push("You have reached the minimum 30 points from categories 1 or 2 above.");
    } else {
      msg.push("You have not reached the minimum 30 points from categories 1 or 2 above.");
    }

    msg.push("Normally, applicants must accumulate a total of 75 points to be considered for membership.")

    return msg.join("  ");
  }

  update() {
    this.totalPointsTarget.innerText = this.total;
    this.messageTarget.innerText = this.message;
  }

  sumQuestionPoints(target) {
    const elements = target.getElementsByTagName("input");
    let total = 0;
    Array.from(elements).forEach(element => {
      let point = Number(element.dataset.points) || 0;
      let value = Number(element.value) || 0;
      total += (point * value);
    });
    return total;
  }

}