//
// Allow launching and uploading of avatar
//
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "image",
    "message",
    "url"
   ];

   static values = {
     "key": String
    }

  get options() {
    return {
      accept: 'image/*',
      fromSources: ['local_file_system', 'facebook', 'googledrive', 'instagram', 'dropbox'],
      maxFiles: 1,
      minFiles: 1,
      onFileUploadFinished: this.update.bind(this)
    };
  }

  get filestack() {
    return filestack.init(this.keyValue);
  }

  launch(event) {
    this._stopDefault(event);
    this.filestack.pick(this.options).then(function(response){
      this.update();
    }.bind(this));
  }

  update(file){
    if(file) {
      this.imageTarget.src = file.url;
      this.urlTarget.value = file.url;
      this.messageTarget.classList.remove('hidden');
    }
  }

  //
  // Private:
  // Prevent events from bubbling
  //
  _stopDefault(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
